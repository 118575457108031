import { FeePlanConstant } from './types/acp-feeplan-constant';

export const ACP_FEEPLAN_CONSTANTS: FeePlanConstant = {
  FEE_NAMES: [
    'domestic_signature_purchase',
    'domestic_pin_purchase',
    'atm_decline',
    'ach_debit_decline',
    'additional_card_order',
    'atm_balance_inquiry',
    'check_request',
    'live_agent',
    'custom_card_order',
    'domestic_atm_withdrawal',
    'ivr_balance_inquiry',
    'over_the_counter',
    'replacement_card_order',
    'statement'
  ],
  PLAN_NAMES: {
    ADVANTAGE_PLAN: 'FeeAdvantage Plan',
    PAYPAL_ADVANTAGE_PLAN: 'PayPal Fee Advantage',
    MONTHLY_FEE_ADVANTAGE: 'Monthly Fee Advantage'
  },
  FEE_NAME: {
    DOMESTIC_ATM_WITHDRAWAL: 'domestic_atm_withdrawal'
  },
  CUSTOMIZED_HEADER_BRAND: ['skylight'],
  CUSTOMIZED_FOOTER_BRAND: ['skylight'],
  CUSTOMIZED_TABLE_BRAND: ['skylight'],
  BRAND: {
    SKYLIGHT: 'skylight'
  }
};
