import {
  AvailableFeePlansResponse,
  CurrentFeePlanResponse,
  FeePlanData,
  FeePlanResponse,
  SubscribePlanRequest
} from '.';
import { AcpFeeplanClient } from './acp-feeplan-client';
import { ACP_FEEPLAN_CONSTANTS } from './acp-feeplan-constant';

export class AcpFeeplanModel {
  public formattedFeePlanData: FeePlanData[] = [];
  constructor(
    private acpFeeplanDomainClient: AcpFeeplanClient,
    private nsPermissions: nsUtils.NsPermissionsService
  ) {
    'ngInject';
  }

  public getAll(): Promise<AvailableFeePlansResponse> {
    return this.acpFeeplanDomainClient.getAll().then((plans) => {
      return plans;
    });
  }
  public subscribe(feePlanId: SubscribePlanRequest): Promise<void> {
    return this.acpFeeplanDomainClient.subscribe(feePlanId);
  }
  public get(): Promise<CurrentFeePlanResponse> {
    return this.acpFeeplanDomainClient.get().then((feeplan) => {
      return feeplan;
    });
  }

  // To hit v1 api for subscribe and current fee plan
  public subscribeNonExpence(feePlanId: SubscribePlanRequest): Promise<void> {
    return this.acpFeeplanDomainClient.subscribeNonExpence(feePlanId);
  }

  public nth(date: number) {
    if (date > 3 && date < 21) return `${date}th`;
    switch (date % 10) {
      case 1:
        return `${date}st`;
      case 2:
        return `${date}nd`;
      case 3:
        return `${date}rd`;
      default:
        return `${date}th`;
    }
  }

  public getFeeDetailsById(
    id,
    feePlans: AvailableFeePlansResponse
  ): FeePlanResponse {
    for (const feePlan of feePlans.plans) {
      if (feePlan.id === id) return feePlan;
    }
  }

  public getPopulatedFeeData(
    feeplans: AvailableFeePlansResponse
  ): FeePlanData[] {
    this.formattedFeePlanData = [];
    for (const feePlan of feeplans.plans) {
      this.populateFeePlanData(feePlan);
    }
    return this.formattedFeePlanData;
  }

  public fetchPermissions(): Promise<nsUtils.NsPermissionResult> {
    return this.nsPermissions.requestPermissions([
      'hasPremierEligible',
      'displayActivateCardCFPB',
      'isFeePlanChangeAllowed',
      'feePlanAndAnytimeAlertsWCAConfigEnabled'
    ]);
  }
  public getPopulatedDataSkylight(feePlan: FeePlanResponse): FeePlanData[] {
    return ACP_FEEPLAN_CONSTANTS.FEE_NAMES.reduce(
      (formattedFeePlanDataSkylight: FeePlanData[], feeName: string) => {
        if (feePlan.fees[feeName]) {
          formattedFeePlanDataSkylight.push({
            feePlanNames: [
              {
                planName: feePlan.name,
                flatFee: feePlan.fees[feeName].flat_fee
              }
            ],
            feeName,
            description: feePlan.fees[feeName].fee_type_description
          });
        }
        return formattedFeePlanDataSkylight;
      },
      []
    );
  }

  private populateFeePlanData(feePlan: FeePlanResponse): void {
    if (this.formattedFeePlanData.length === 0) {
      this.populateFormattedFeePlanData(feePlan);
    } else {
      this.formattedFeePlanData.forEach((feeDetail: FeePlanData) => {
        const temp = feePlan.fees[feeDetail.feeName];
        feeDetail.feePlanNames.push({
          planName: feePlan.name,
          flatFee: temp.flat_fee
        });
      });
    }
  }

  private populateFormattedFeePlanData(feePlan: FeePlanResponse): void {
    ACP_FEEPLAN_CONSTANTS.FEE_NAMES.forEach((feeName) => {
      if (feePlan.fees[feeName]) {
        this.formattedFeePlanData.push({
          feePlanNames: [
            { planName: feePlan.name, flatFee: feePlan.fees[feeName].flat_fee }
          ],
          feeName,
          description: feePlan.fees[feeName].fee_type_description
        });
      }
    });
  }
}
