import {
  AvailableFeePlansResponse,
  CurrentFeePlanResponse,
  SubscribePlanRequest
} from '.';
import { IFeePlanUrlService } from './acp-feeplan-url-provider';

export class AcpFeeplanClient {
  public getAll = this.webapiResource<void, AvailableFeePlansResponse>({
    method: 'GET',
    path: this.acpFeePlanUrl.get() + '/feeplan/available',
    cache: true,
    transformData: this.transformData.bind(this)
  });

  public subscribe = this.webapiResource<SubscribePlanRequest, void>({
    method: 'PUT',
    path: this.acpFeePlanUrl.get() + '/feeplan/:id'
  });

  public get = this.webapiResource<void, CurrentFeePlanResponse>({
    method: 'GET',
    path: this.acpFeePlanUrl.get() + '/feeplan',
    transformData: this.transformSinglePlanData.bind(this)
  });

  // To hit v1 api for subscribe and current fee plan
  public subscribeNonExpence = this.webapiResource<SubscribePlanRequest, void>({
    method: 'PUT',
    path: '/v1/feeplan/:id'
  });

  constructor(
    private webapiResource: nsWebclient.WebapiResourceService,
    private nsUtil: nsUtils.NsUtilService,
    private acpFeePlanUrl: IFeePlanUrlService
  ) {
    'ngInject';
  }

  public decorateFeePlanFees(fees) {
    for (const key in fees) {
      if (fees.hasOwnProperty(key)) {
        if (typeof fees[key] === 'object') {
          this.decorateFeePlanFees.call(this, fees[key]);
        } else if (
          typeof fees[key] !== 'object' &&
          typeof fees[key] !== 'string'
        ) {
          fees[key] = this.nsUtil.toDollars(fees[key]);
        }
      }
    }
    return fees;
  }

  private transformData(feeplans) {
    feeplans.plans.map((plan) => {
      plan._cost = this.nsUtil.toDollars(plan.cost);
      this.decorateFeePlanFees(plan.fees);
      return plan;
    });
    return feeplans;
  }

  private transformSinglePlanData(feeplan) {
    feeplan.plans = [feeplan.plan];
    this.transformData(feeplan);
    feeplan.plan = feeplan.plans[0];
    delete feeplan.plans;
    return feeplan;
  }
}
