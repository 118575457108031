import ng from 'angular';
import acpCore from 'core';
import { AcpFeeplanClient } from './acp-feeplan-client';
import { ACP_FEEPLAN_CONSTANTS } from './acp-feeplan-constant';
import { AcpFeeplanModel } from './acp-feeplan-model';
import { FeePlanUrlService } from './acp-feeplan-url-provider';

export const acpFeePlanDomain = ng
  .module('acp.component.feeplan-domain', [acpCore.name])
  .service('acpFeeplanDomainClient', AcpFeeplanClient)
  .service('acpFeeplanDomainModel', AcpFeeplanModel)
  .provider('acpFeePlanUrl', FeePlanUrlService);

acpFeePlanDomain.config((acpFeePlanUrlProvider, nsConfigProvider) => {
  'ngInject';

  acpFeePlanUrlProvider.setUrl(
    nsConfigProvider.get('brand') === 'netspendExpense'
      ? '/v2/expensemgmt'
      : '/v2'
  );
});

export default acpFeePlanDomain;
export { ACP_FEEPLAN_CONSTANTS };
export { AcpFeeplanClient, AcpFeeplanModel };
export * from './types';
export { FeePlanResponse } from './types/acp-fee-response';
